/* eslint-disable max-lines */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScheduleShiftTemplateComponent } from '@app/+authenticated/+schedule/employee/schedule-employee-n-period/schedule-items/scheduled-shift/default/schedule-shift-template.component';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { CustomFieldPipe } from '@app/pipes/custom-field.pipe';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { RecurringScheduleHtmlPipe } from '@app/pipes/recurring-schedule-html.pipe';
import { DepartmentDeactivationGuard } from '@app/reducers/orm/department/department-deactivation.guard';
import { PermissionType } from '@app/reducers/orm/permission/permission.model';
import { MultiselectComponent } from '@app/shared/multiselect/multiselect.component';
import { OldNoticeComponent } from '@app/shared/sb-lib/notice/notice.component';
import { TooltipModule } from '@sb/tooltip';
import { BadgeComponent, CheckboxComponent, NoticeComponent, SelectComponent } from '@sb/ui';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { StepsModule } from 'primeng/steps';

import { SharedEmployeeModule } from '../+authenticated/+employees/shared/shared-employee.module';
import { PlanType, QuotaType } from '../+authenticated/+reports/shared/subscriptions/subscription.model';
import { SharedTimesheetModule } from '../+authenticated/+timesheet/shared/shared-timesheet.module';
import { PermissionGuard } from '../+authenticated/permission-guard.service';
import { AvatarPermissionGuard } from '../+authenticated/shared/avatar/avatar-permission-guard.service';
import { SbDropdownModule } from '../+authenticated/shared/dropdown/sb-dropdown.module';
import { SharedAuthModule } from '../+authenticated/shared/shared-auth.module';
import { Features } from '../enums';
import { DecimalPipe } from '../pipes/decimal.pipe';
import { KnowledgeBaseArticleLinkModule } from '../pipes/knowledge-base-article-link.module';
import { AbsenceExistsGuard } from '../reducers/orm/absence/absence-exists.guard';
import { AvailabilityExistsGuard } from '../reducers/orm/availability/availability-exists.guard';
import { ClockIpAddressExistsGuard } from '../reducers/orm/clock-ip-address/clock-ip-address-exists.guard';
import { ContractTypeExistsGuard } from '../reducers/orm/contract-type/contract-type-exists.guard';
import { DepartmentExistsGuard } from '../reducers/orm/department/department-exists.guard';
import { EventLoadGuard } from '../reducers/orm/event/event-load.guard';
import { ExchangeLoadGuard } from '../reducers/orm/exchange/exchange-load.guard';
import { HolidayExistsGuard } from '../reducers/orm/holiday/holiday-exists.guard';
import { IntegrationAppParamGuard } from '../reducers/orm/integration-apps/integration-app-param.guard';
import { IntegrationAppGuard } from '../reducers/orm/integration-apps/integration-app.guard';
import { IntegrationAppTitle } from '../reducers/orm/integration-apps/integration-app.model';
import { LocationExistsGuard } from '../reducers/orm/location/location-exists.guard';
import { OpenShiftExistsGuard } from '../reducers/orm/open-shift/open-shift-exists.quard';
import { PermissionGroupExistsGuard } from '../reducers/orm/permission-group/permission-group-exists.guard';
import { RateCardExistsGuard } from '../reducers/orm/rate-card/rate-card-exists.guard';
import { RequiredShiftExistsGuard } from '../reducers/orm/required-shift/required-shift-exists.quard';
import { ScheduleExchangeLoadGuard } from '../reducers/orm/schedule/schedule-exchange-load.guard';
import { ScheduleExistsGuard } from '../reducers/orm/schedule/schedule-exists.guard';
import { SkillGroupExistsGuard } from '../reducers/orm/skill-group/skill-group-exists.guard';
import { SkillExistsGuard } from '../reducers/orm/skill/skill-exists.guard';
import { SurchargeExistsGuard } from '../reducers/orm/surcharge/surcharge-exists.guard';
import { TimesheetExistsGuard } from '../reducers/orm/timesheet/timesheet-exists.guard';
import { CustomFieldGuard } from '../shared/custom-field/custom-field.guard';
import { SbFormModules } from '../shared/form-input/sb-form.modules';
import { FeatureFlagModalRedirectGuard } from '../shared/guards/feature-flag-modal-redirect/feature-flag-modal-redirect.guard';
import { FeatureFlagGuard } from '../shared/guards/feature-flag/feature-flag.guard';
import { SbTableSelectModule } from '../shared/sb-lib/select/base-table-select/sb-table-select.module';
import { SharedModule } from '../shared/shared.module';
import { SubscriptionPlanGuard } from '../shared/subscription-plan/subscription-plan.guard';
import { SubscriptionQuotaGuard } from '../shared/subscription-plan/subscription-quota.guard';
import { AppConfigureModule } from './integrations/configure/app-configure.module';
import { IntroductionModalService } from './introduction/introduction-modal.service';
import { ReportFavoriteModalComponent } from './report/favorites/create/report-favorite.modal.component';
import { ReportFavoriteListModalComponent } from './report/favorites/list/report-favorite-list-modal.component';
import { ReportRecurringModalComponent } from './report/recurring/create/report-recurring-modal.component';
import { TimesheetClockInfoComponent } from './timesheet/timesheet-hours/clock-location/timesheet-clock-info.component';

export const modalRoutes: Routes = [
  {
    path: 'authenticator',
    loadComponent: () => import('./auth/authenticator/authenticator.component').then((m) => m.AuthenticatorComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'authenticator/disable',
    loadComponent: () =>
      import('./auth/authenticator-disable/authenticator-disable.component').then(
        (m) => m.AuthenticatorDisableComponent,
      ),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'backup-code',
    loadComponent: () => import('./auth/backup-code/backup-code.component').then((m) => m.BackupCodeComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'backup-email',
    loadComponent: () => import('./auth/backup-email/backup-email.component').then((m) => m.BackupEmailComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'support',
    loadChildren: () => import('./sb-support/sb-support.module').then((m) => m.SbSupportModule),
    outlet: 'modal',
  },
  {
    path: 'availability',
    loadComponent: () =>
      import('../+modals/schedule/availability/availability.component').then((m) => m.AvailabilityComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard, AvailabilityExistsGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'calendar-sync',
    loadComponent: () =>
      import('../+modals/schedule/custom-calendar-sync/custom-calendar-sync.component').then(
        (m) => m.CustomCalendarSyncComponent,
      ),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'calendar-sync/add',
    loadComponent: () =>
      import('../+modals/schedule/custom-calendar-sync-edit/custom-calendar-sync-edit.component').then(
        (m) => m.CustomCalendarSyncEditComponent,
      ),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'calendar-sync/:id',
    loadComponent: () =>
      import('../+modals/schedule/custom-calendar-sync-edit/custom-calendar-sync-edit.component').then(
        (m) => m.CustomCalendarSyncEditComponent,
      ),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'send-schedule',
    loadComponent: () =>
      import('../+modals/schedule/send-schedule/send-schedule.component').then((m) => m.SendScheduleComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'print-schedule',
    loadComponent: () =>
      import('../+modals/schedule/print-schedule/print-schedule.component').then((m) => m.PrintScheduleComponent),
    outlet: 'modal',
  },
  {
    path: 'publish-schedule/:period/:date',
    loadComponent: () =>
      import('../+modals/schedule/publish-schedule/publish-schedule.component').then((m) => m.PublishScheduleComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'manage-forecast/:date',
    loadComponent: () =>
      import('../+modals/schedule/manage-forecast/manage-forecast.component').then((m) => m.ManageForecastComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },

  {
    path: 'manage-budget/:date',
    loadComponent: () =>
      import('../+modals/schedule/manage-budget/manage-budget.component').then((m) => m.ManageBudgetComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },

  {
    path: 'shift',
    loadComponent: () => import('../+modals/schedule/shift/shift.component').then((m) => m.ModalShiftComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'shift/:department_id',
    loadComponent: () => import('../+modals/schedule/shift/shift.component').then((m) => m.ModalShiftComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'shift/edit/:shift_id',
    loadComponent: () => import('../+modals/schedule/shift/shift.component').then((m) => m.ModalShiftComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'team',
    loadComponent: () => import('../+modals/schedule/team/team.component').then((m) => m.TeamComponent),
    outlet: 'modal',
    data: {
      quotaType: QuotaType.TEAMS,
    },
    canActivate: [SubscriptionQuotaGuard],
  },
  {
    path: 'team/:department_id',
    loadComponent: () => import('../+modals/schedule/team/team.component').then((m) => m.TeamComponent),
    outlet: 'modal',
    data: {
      quotaType: QuotaType.TEAMS,
    },
    canActivate: [SubscriptionQuotaGuard],
  },
  {
    path: 'team/edit/:team_id',
    loadComponent: () => import('../+modals/schedule/team/team.component').then((m) => m.TeamComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'bulk-message',
    loadComponent: () => import('./employee/message/message.component').then((m) => m.MessageComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'exchange-request',
    loadComponent: () =>
      import('../+modals/schedule/exchange-request/exchange-request.component').then((m) => m.ExchangeRequestComponent),
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
    canActivate: [SubscriptionPlanGuard],
  },
  {
    path: 'exchange-request/:id',
    loadComponent: () =>
      import('../+modals/schedule/exchange-request/view-exchange-request.component').then(
        (m) => m.ViewExchangeRequestComponent,
      ),
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
    canActivate: [ExchangeLoadGuard, SubscriptionPlanGuard],
  },
  {
    path: 'exchange-request/:id/edit',
    loadComponent: () =>
      import('../+modals/schedule/exchange-request/exchange-request.component').then((m) => m.ExchangeRequestComponent),
    canActivate: [PermissionGuard, ExchangeLoadGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-shift',
    loadComponent: () =>
      import('../+modals/schedule/schedule-shift/schedule-shift.component').then((m) => m.ScheduleShiftComponent),
    outlet: 'modal',
    canActivate: [PermissionGuard],
  },
  {
    path: 'schedule-shift/:id',
    loadComponent: () =>
      import('../+modals/schedule/schedule-shift-view/schedule-shift-view.component').then(
        (m) => m.ScheduleShiftViewComponent,
      ),
    canActivate: [PermissionGuard, ScheduleExistsGuard, ScheduleExchangeLoadGuard],
    outlet: 'modal',
  },
  {
    path: 'schedule-shift/:id/edit',
    loadComponent: () =>
      import('../+modals/schedule/schedule-shift/schedule-shift-edit.component').then(
        (m) => m.ScheduleShiftEditComponent,
      ),
    canActivate: [PermissionGuard, ScheduleExistsGuard],
    outlet: 'modal',
  },
  {
    path: 'schedule-shift/:id/delete',
    loadComponent: () =>
      import('../+modals/schedule/schedule-shift-delete/schedule-shift-delete.component').then(
        (m) => m.ScheduleShiftDeleteComponent,
      ),
    canActivate: [PermissionGuard, ScheduleExistsGuard],
    outlet: 'modal',
  },
  {
    path: 'event',
    loadComponent: () => import('../+modals/schedule/event/event.component').then((m) => m.EventComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'event/:id',
    loadComponent: () =>
      import('../+modals/schedule/event-view/event-view.component').then((m) => m.EventViewComponent),
    canActivate: [PermissionGuard, EventLoadGuard],
    outlet: 'modal',
  },
  {
    path: 'event/:id/edit',
    loadComponent: () => import('../+modals/schedule/event/event.component').then((m) => m.EventComponent),
    canActivate: [PermissionGuard, EventLoadGuard],
    outlet: 'modal',
  },
  {
    path: 'event/:id/delete',
    loadComponent: () =>
      import('../+modals/schedule/event-delete/event-delete.component').then((m) => m.EventDeleteComponent),
    canActivate: [PermissionGuard, EventLoadGuard],
    outlet: 'modal',
  },
  {
    path: 'schedule-open-shift',
    loadComponent: () =>
      import('../+modals/schedule/schedule-openshift-add/schedule-openshift-add.component').then(
        (m) => m.ScheduleOpenShiftAddComponent,
      ),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'open-shift-disable-approval',
    loadComponent: () =>
      import('../+modals/schedule/openshift/openshift-approval-confirm/openshift-approval-confirm.component').then(
        (m) => m.OpenshiftApprovalConfirmComponent,
      ),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'schedule-open-shift/:id',
    loadComponent: () =>
      import('../+modals/schedule/schedule-openshift-view/schedule-openshift-view.component').then(
        (m) => m.ScheduleOpenShiftViewComponent,
      ),
    canActivate: [PermissionGuard, OpenShiftExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-open-shift/:id/assign',
    loadComponent: () =>
      import('../+modals/schedule/schedule-openshift-assign/schedule-openshift-assign.component').then(
        (m) => m.ScheduleOpenShiftAssignComponent,
      ),
    canActivate: [PermissionGuard, OpenShiftExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-open-shift/:id/withdraw',
    loadComponent: () =>
      import(
        '../+modals/schedule/schedule-openshift-view/schedule-openshift-withdraw-confirm/schedule-openshift-withdraw-confirm.component'
      ).then((m) => m.ScheduleOpenshiftWithdrawConfirmComponent),
    canActivate: [PermissionGuard, OpenShiftExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-open-shift/:id/request',
    loadComponent: () =>
      import(
        '../+modals/schedule/schedule-openshift-view/schedule-openshift-rerequest-confirm/schedule-openshift-rerequest-confirm.component'
      ).then((m) => m.ScheduleOpenshiftRerequestConfirmComponent),
    canActivate: [PermissionGuard, OpenShiftExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-open-shift/:id/edit',
    loadComponent: () =>
      import('../+modals/schedule/schedule-openshift-edit/schedule-openshift-edit.component').then(
        (m) => m.ScheduleOpenShiftEditComponent,
      ),
    canActivate: [PermissionGuard, OpenShiftExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-open-shift/:id/delete',
    loadComponent: () =>
      import('../+modals/schedule/schedule-openshift-delete/schedule-openshift-delete.component').then(
        (m) => m.ScheduleOpenShiftDeleteComponent,
      ),
    canActivate: [PermissionGuard, OpenShiftExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-required-shift',
    loadComponent: () =>
      import('../+modals/schedule/schedule-required-shift-add/schedule-required-shift-add.component').then(
        (m) => m.ScheduleRequiredShiftAddComponent,
      ),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-required-shift/:id',
    loadComponent: () =>
      import('../+modals/schedule/schedule-required-shift-view/schedule-required-shift-view.component').then(
        (m) => m.ScheduleRequiredShiftViewComponent,
      ),
    canActivate: [PermissionGuard, RequiredShiftExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-required-shift/:id/edit',
    loadComponent: () =>
      import('../+modals/schedule/schedule-required-shift-edit/schedule-required-shift-edit.component').then(
        (m) => m.ScheduleRequiredShiftEditComponent,
      ),
    canActivate: [PermissionGuard, RequiredShiftExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'schedule-required-shift/:id/delete',
    loadComponent: () =>
      import('../+modals/schedule/schedule-required-shift-delete/schedule-required-shift-delete.component').then(
        (m) => m.ScheduleRequiredShiftDeleteComponent,
      ),
    canActivate: [PermissionGuard, RequiredShiftExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'news-item',
    loadComponent: () => import('./news/news-item/news-item.component').then((m) => m.NewsItemComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'news-item/:id',
    loadComponent: () => import('./news/news-item/news-item.component').then((m) => m.NewsItemComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'open-close-days/:period/:date',
    loadComponent: () =>
      import('./timesheet/open-close-days/open-close-days.component').then((m) => m.OpenCloseDaysComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'timesheet',
    loadComponent: () =>
      import('./timesheet/timesheet-hours/timesheet-hours.component').then((m) => m.TimesheetHoursComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
    data: {
      permission: ['Create timesheets', 'Create own timesheet'],
    },
  },
  {
    path: 'timesheet/:id',
    loadComponent: () =>
      import('./timesheet/timesheet-hours-view/timesheet-hours-view.component').then(
        (m) => m.TimesheetHoursViewComponent,
      ),
    canActivate: [PermissionGuard, TimesheetExistsGuard],
    outlet: 'modal',
    data: {
      permission: ['View all timesheets'],
    },
  },
  {
    path: 'timesheet/:id/edit',
    loadComponent: () =>
      import('./timesheet/timesheet-hours/timesheet-hours.component').then((m) => m.TimesheetHoursComponent),
    canActivate: [PermissionGuard, TimesheetExistsGuard],
    outlet: 'modal',
    data: {
      permission: ['Edit timesheets', 'Edit own timesheet', 'Approve timesheets'],
    },
  },
  {
    path: 'clock-employee',
    loadComponent: () => import('./employee/clock/clock.component').then((m) => m.ClockComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
      ownClock: false,
    },
  },
  {
    path: 'clock',
    loadComponent: () => import('./employee/clock/clock.component').then((m) => m.ClockComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'clock-switch',
    loadComponent: () => import('./employee/clock-switch/clock-switch.component').then((m) => m.ClockSwitchComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'employee-avatar/:id',
    loadComponent: () =>
      import('./employee/employee-avatar/employee-avatar.component').then((m) => m.EmployeeAvatarModalComponent),
    canActivate: [AvatarPermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'quick-user-import',
    loadComponent: () =>
      import('./employee/quick-user-import/quick-user-import.component').then((m) => m.QuickUserImportComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'csv-user-import',
    loadComponent: () =>
      import('./employee/csv-user-import/csv-user-import.component').then((m) => m.CsvUserImportComponent),
    outlet: 'modal',
  },
  {
    path: 'employee-file/:employeeId',
    loadComponent: () =>
      import('./employee/employee-file/employee-file.component').then((m) => m.EmployeeFileComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'employee-file/:employeeId/:attachmentId',
    loadComponent: () =>
      import('./employee/employee-file/employee-file.component').then((m) => m.EmployeeFileComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'employee-note/:employeeId',
    loadComponent: () =>
      import('./employee/employee-note/employee-note.component').then((m) => m.EmployeeNoteComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'employee-note/:employeeId/:noteId',
    loadComponent: () =>
      import('./employee/employee-note/employee-note.component').then((m) => m.EmployeeNoteComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'employee-create',
    loadComponent: () => import('./employee/employee-modal.component').then((m) => m.EmployeeModalComponent),
    outlet: 'modal',
    canActivate: [PermissionGuard],
    data: {
      permission: ['Create users'],
    },
  },

  // New Add Absence modal
  {
    path: 'add-absence',
    loadComponent: () => import('./employee/absence-request/absence-add.component').then((m) => m.AbsenceAddComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard, FeatureFlagGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
      featureFlag: Features.TMP_NEW_ADD_ABSENCE_MODAL,
    },
  },
  // Old Add Absence modal
  {
    path: 'absentee-days-request',
    loadComponent: () =>
      import('./employee/employee-absentee-days/employee-absentee-days.component').then(
        (m) => m.EmployeeAbsenteeDaysModalComponent,
      ),
    canActivate: [PermissionGuard, SubscriptionPlanGuard, FeatureFlagModalRedirectGuard],
    outlet: 'modal',
    data: {
      featureFlag: Features.TMP_NEW_ADD_ABSENCE_MODAL,
      redirectTo: 'add-absence',
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'absentee-days-request/:id',
    loadComponent: () =>
      import('./employee/employee-absentee-days/employee-absentee-days.component').then(
        (m) => m.EmployeeAbsenteeDaysModalComponent,
      ),
    canActivate: [PermissionGuard, AbsenceExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'absentee-option',
    loadComponent: () =>
      import('../+modals/settings/absence-type/absence-type.component').then((m) => m.AbsenceTypeModalComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
      permission: PermissionType.MANAGE_ACCOUNT,
    },
  },
  {
    path: 'absentee-option/:id',
    loadComponent: () =>
      import('../+modals/settings/absence-type/absence-type.component').then((m) => m.AbsenceTypeModalComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
      permission: PermissionType.MANAGE_ACCOUNT,
    },
  },
  {
    path: 'time-off-correction/:correctionType/:employeeId',
    loadComponent: () =>
      import('./employee/time-off-correction/time-off-correction.component').then((m) => m.TimeOffCorrectionComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      permission: ['Edit plus min hours', 'Adjust time off balances'],
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'time-off-correction/:correctionType/:employeeId/:balanceId',
    loadComponent: () =>
      import('./employee/time-off-correction/time-off-correction.component').then((m) => m.TimeOffCorrectionComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      permission: ['Edit plus min hours', 'Adjust time off balances'],
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'contract',
    loadComponent: () => import('./employee/contract/contract.component').then((m) => m.ContractComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'contract/:contractId',
    loadComponent: () => import('./employee/contract/contract.component').then((m) => m.ContractComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },

  {
    path: 'company-file',
    loadComponent: () =>
      import('../+modals/settings/company-file/company-file.component').then((m) => m.CompanyFileComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'company-file/:attachmentId',
    loadComponent: () =>
      import('../+modals/settings/company-file/company-file.component').then((m) => m.CompanyFileComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },

  {
    path: 'permission-group',
    loadComponent: () =>
      import('../+modals/settings/permission-group/permission-group.component').then((m) => m.PermissionGroupComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'permission-group/:id',
    loadComponent: () =>
      import('../+modals/settings/permission-group/permission-group.component').then((m) => m.PermissionGroupComponent),
    canActivate: [PermissionGuard, PermissionGroupExistsGuard],
    outlet: 'modal',
  },

  {
    path: 'location-group',
    loadComponent: () =>
      import('../+modals/settings/location-group/location-group.component').then((m) => m.LocationGroupComponent),
    canActivate: [PermissionGuard],
    outlet: 'modal',
  },
  {
    path: 'location-group/:id',
    loadComponent: () =>
      import('../+modals/settings/location-group/location-group.component').then((m) => m.LocationGroupComponent),
    canActivate: [PermissionGuard, LocationExistsGuard],
    outlet: 'modal',
  },
  {
    path: 'location',
    loadComponent: () => import('../+modals/settings/location/location.component').then((m) => m.LocationComponent),
    canActivate: [PermissionGuard, SubscriptionQuotaGuard],
    outlet: 'modal',
    data: {
      quotaType: QuotaType.DEPARTMENTS,
    },
  },
  {
    path: 'location/:id',
    loadComponent: () => import('../+modals/settings/location/location.component').then((m) => m.LocationComponent),
    canActivate: [PermissionGuard, DepartmentExistsGuard],
    outlet: 'modal',
  },
  {
    path: 'time-off-contract-type',
    loadComponent: () =>
      import('../+modals/settings/time-off-contract-type/time-off-contract-type.component').then(
        (m) => m.TimeOffContractTypeComponent,
      ),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'time-off-contract-type/:id',
    loadComponent: () =>
      import('../+modals/settings/time-off-contract-type/time-off-contract-type.component').then(
        (m) => m.TimeOffContractTypeComponent,
      ),
    canActivate: [PermissionGuard, ContractTypeExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'skill',
    loadComponent: () => import('../+modals/settings/skill/skill.component').then((m) => m.SkillComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'skill/:id',
    loadComponent: () => import('../+modals/settings/skill/skill.component').then((m) => m.SkillComponent),
    canActivate: [PermissionGuard, SkillExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'skill-group',
    loadComponent: () =>
      import('../+modals/settings/skill-group/skill-group.component').then((m) => m.SkillGroupComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'skill-group/:id',
    loadComponent: () =>
      import('../+modals/settings/skill-group/skill-group.component').then((m) => m.SkillGroupComponent),
    canActivate: [PermissionGuard, SkillGroupExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'rate-card',
    loadComponent: () => import('../+modals/settings/rate-card/rate-card.component').then((m) => m.RateCardComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'rate-card/copy/:id',
    loadComponent: () => import('../+modals/settings/rate-card/rate-card.component').then((m) => m.RateCardComponent),
    canActivate: [PermissionGuard, RateCardExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      copy: true,
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'rate-card/:id',
    loadComponent: () => import('../+modals/settings/rate-card/rate-card.component').then((m) => m.RateCardComponent),
    canActivate: [PermissionGuard, RateCardExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'rates/:id',
    loadComponent: () => import('./timesheet/rates-view/rates-view.component').then((m) => m.RatesViewComponent),
    canActivate: [PermissionGuard, TimesheetExistsGuard],
    outlet: 'modal',
  },
  {
    path: 'surcharge',
    loadComponent: () => import('../+modals/settings/surcharge/surcharge.component').then((m) => m.SurchargeComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'holiday',
    loadComponent: () => import('../+modals/settings/holiday/holiday.component').then((m) => m.HolidayComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'holiday/:id',
    loadComponent: () => import('../+modals/settings/holiday/holiday.component').then((m) => m.HolidayComponent),
    canActivate: [PermissionGuard, HolidayExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'holiday-group',
    loadComponent: () =>
      import('../+modals/settings/holiday-group/holiday-group.component').then((m) => m.HolidayGroupComponent),
    outlet: 'modal',
    canActivate: [PermissionGuard, SubscriptionPlanGuard, SubscriptionQuotaGuard],
    data: {
      permission: ['Manage account'],
      subscriptionPlan: PlanType.BASIC,
      quotaType: QuotaType.HOLIDAY_GROUPS,
    },
  },
  {
    path: 'integration',
    loadComponent: () =>
      import('../+modals/integrations/integration/integration.component').then((m) => m.IntegrationComponent),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'integration/:id',
    loadComponent: () =>
      import('../+modals/integrations/integration/integration.component').then((m) => m.IntegrationComponent),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'tonit-configure',
    loadComponent: () =>
      import('../+modals/integrations/tonit-configure/tonit-configure.component').then(
        (m) => m.TonitConfigureComponent,
      ),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'tonit-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/tonit-configure/tonit-configure.component').then(
        (m) => m.TonitConfigureComponent,
      ),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'app-token',
    loadComponent: () =>
      import('../+modals/integrations/app-token/app-token.component').then((m) => m.AppTokenComponent),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'app-token/:id',
    loadComponent: () =>
      import('../+modals/integrations/app-token/app-token.component').then((m) => m.AppTokenComponent),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'piggy-configure',
    loadComponent: () =>
      import('../+modals/integrations/piggy-configure/piggy-configure.component').then(
        (m) => m.PiggyConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.PIGGY,
    },
  },
  {
    path: 'piggy-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/piggy-configure/piggy-configure.component').then(
        (m) => m.PiggyConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.PIGGY,
    },
  },
  {
    path: 'piggyv2-configure',
    loadComponent: () =>
      import('../+modals/integrations/piggy-v2-configure/piggy-v2-configure.component').then(
        (m) => m.PiggyV2ConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.PIGGY_V2,
    },
  },
  {
    path: 'piggyv2-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/piggy-v2-configure/piggy-v2-configure.component').then(
        (m) => m.PiggyV2ConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.PIGGY_V2,
    },
  },
  {
    path: 'datafox-configure',
    loadComponent: () =>
      import('../+modals/integrations/datafox-configure/datafox-configure.component').then(
        (m) => m.DatafoxConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.DATAFOX,
    },
  },
  {
    path: 'datafox-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/datafox-configure/datafox-configure.component').then(
        (m) => m.DatafoxConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.DATAFOX,
    },
  },
  {
    path: 'oneteam-request',
    loadComponent: () =>
      import('../+modals/integrations/oneteam-configure/oneteam-request.component').then(
        (m) => m.OneteamRequestComponent,
      ),
    canActivate: [PermissionGuard, IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.ONETEAM,
    },
  },
  {
    path: 'oneteam-configure',
    loadComponent: () =>
      import('../+modals/integrations/oneteam-configure/oneteam-configure.component').then(
        (m) => m.OneteamConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.ONETEAM,
    },
  },
  {
    path: 'oneteam-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/oneteam-configure/oneteam-configure.component').then(
        (m) => m.OneteamConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.ONETEAM,
    },
  },
  {
    path: 'datafox-request',
    loadComponent: () =>
      import('../+modals/integrations/datafox-configure/datafox-request.component').then(
        (m) => m.DatafoxRequestComponent,
      ),
    canActivate: [PermissionGuard, IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.DATAFOX,
    },
  },
  {
    path: 'datafox-badge',
    loadComponent: () =>
      import(
        '../+authenticated/+account/+integrations/+datafox/datafox-mapping/datafox-general/add-badge/datafox-badge.component'
      ).then((m) => m.DatafoxBadgeComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'datafox-bulk-badges',
    loadComponent: () =>
      import(
        '../+authenticated/+account/+integrations/+datafox/datafox-mapping/datafox-general/add-bulk-badges/datafox-bulk-badges.component'
      ).then((m) => m.DatafoxBulkBadgesComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'nmbrs-configure',
    loadComponent: () =>
      import('../+modals/integrations/nmbrs-configure/nmbrs-configure.component').then(
        (m) => m.NmbrsConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.NMBRS,
    },
  },
  {
    path: 'nmbrs-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/nmbrs-configure/nmbrs-configure.component').then(
        (m) => m.NmbrsConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.NMBRS,
    },
  },
  {
    path: 'lightspeed-k-series-configure',
    loadComponent: () =>
      import('../+modals/integrations/lightspeed-k-series-configure/lightspeed-k-series-configure.component').then(
        (m) => m.LightspeedKSeriesConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LIGHTSPEED_K_SERIES,
    },
  },
  {
    path: 'lightspeed-k-series-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/lightspeed-k-series-configure/lightspeed-k-series-configure.component').then(
        (m) => m.LightspeedKSeriesConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LIGHTSPEED_K_SERIES,
    },
  },
  {
    path: 'loketv2-configure',
    loadComponent: () =>
      import('../+modals/integrations/loket-v2/loket-v2-configure.component').then((m) => m.LoketV2ConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LOKET_V2,
    },
  },
  {
    path: 'loketv2-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/loket-v2/loket-v2-configure.component').then((m) => m.LoketV2ConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LOKET_V2,
    },
  },
  {
    path: 'gastrofix-configure',
    loadComponent: () =>
      import('../+modals/integrations/gastrofix-configure/gastrofix-configure.component').then(
        (m) => m.GastrofixConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.GASTROFIX,
    },
  },
  {
    path: 'gastrofix-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/gastrofix-configure/gastrofix-configure.component').then(
        (m) => m.GastrofixConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.GASTROFIX,
    },
  },
  {
    path: 'gastrofix-request',
    loadComponent: () =>
      import('../+modals/integrations/gastrofix-configure/gastrofix-request.component').then(
        (m) => m.GastrofixRequestComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.GASTROFIX,
    },
  },
  {
    path: 'mplus-configure',
    loadComponent: () =>
      import('../+modals/integrations/mplus-configure/mplus-configure.component').then(
        (m) => m.MPlusConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.MPLUS,
    },
  },
  {
    path: 'mplus-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/mplus-configure/mplus-configure.component').then(
        (m) => m.MPlusConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.MPLUS,
    },
  },
  {
    path: 'mplus-request',
    loadComponent: () =>
      import('../+modals/integrations/mplus-configure/mplus-request.component').then((m) => m.MPlusRequestComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.MPLUS,
    },
  },
  {
    path: 'lightspeed-restaurant-v2-configure',
    loadComponent: () =>
      import(
        '../+modals/integrations/lightspeed-restaurant-v2-configure/lightspeed-restaurant-v2-configure.component'
      ).then((m) => m.LightspeedRestaurantV2ConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LIGHTSPEED_RESTAURANT_V2,
    },
  },
  {
    path: 'lightspeed-restaurant-v2-configure/:id',
    loadComponent: () =>
      import(
        '../+modals/integrations/lightspeed-restaurant-v2-configure/lightspeed-restaurant-v2-configure.component'
      ).then((m) => m.LightspeedRestaurantV2ConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LIGHTSPEED_RESTAURANT_V2,
    },
  },
  {
    path: 'untill-configure',
    loadComponent: () =>
      import('../+modals/integrations/untill-configure/untill-configure.component').then(
        (m) => m.UntillConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.UNTILL,
    },
  },
  {
    path: 'untill-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/untill-configure/untill-configure.component').then(
        (m) => m.UntillConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.UNTILL,
    },
  },
  {
    path: 'tomtom-configure',
    loadComponent: () =>
      import('../+modals/integrations/tomtom-configure/tomtom-configure.component').then(
        (m) => m.TomtomConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.TOMTOM,
    },
  },
  {
    path: 'tomtom-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/tomtom-configure/tomtom-configure.component').then(
        (m) => m.TomtomConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.TOMTOM,
    },
  },
  {
    path: 'ncr-configure',
    loadComponent: () =>
      import('../+modals/integrations/ncr-configure/ncr-configure.component').then((m) => m.NcrConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.NCR,
    },
  },
  {
    path: 'ncr-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/ncr-configure/ncr-configure.component').then((m) => m.NcrConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.NCR,
    },
  },
  {
    path: 'unitouch-configure',
    loadComponent: () =>
      import('../+modals/integrations/unitouch-configure/unitouch-configure.component').then(
        (m) => m.UnitouchConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.UNITOUCH,
    },
  },
  {
    path: 'unitouch-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/unitouch-configure/unitouch-configure.component').then(
        (m) => m.UnitouchConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.UNITOUCH,
    },
  },
  {
    path: 'euur-configure',
    loadComponent: () =>
      import('../+modals/integrations/euur-configure/euur-configure.component').then((m) => m.EuurConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.EUUR,
    },
  },
  {
    path: 'euur-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/euur-configure/euur-configure.component').then((m) => m.EuurConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.EUUR,
    },
  },
  {
    path: 'vectron-configure',
    loadComponent: () =>
      import('../+modals/integrations/vectron-configure/vectron-configure.component').then(
        (m) => m.VectronConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.VECTRON,
    },
  },
  {
    path: 'vectron-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/vectron-configure/vectron-configure.component').then(
        (m) => m.VectronConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.VECTRON,
    },
  },
  {
    path: 'mrwinston-configure',
    loadComponent: () =>
      import('../+modals/integrations/mrwinston-configure/mrwinston-configure.component').then(
        (m) => m.MrWinstonConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.WINSTON,
    },
  },
  {
    path: 'mrwinston-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/mrwinston-configure/mrwinston-configure.component').then(
        (m) => m.MrWinstonConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.WINSTON,
    },
  },
  {
    path: 'exact-configure',
    loadComponent: () =>
      import('../+modals/integrations/exact/exact-configure/exact-configure.component').then(
        (m) => m.ExactConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.EXACT,
    },
  },
  {
    path: 'exact-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/exact/exact-configure/exact-configure.component').then(
        (m) => m.ExactConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.EXACT,
    },
  },
  {
    path: 'lightspeed-retail-configure',
    loadComponent: () =>
      import('../+modals/integrations/lightspeed-retail/lightspeed-retail-configure.component').then(
        (m) => m.LightspeedRetailConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LIGHTSPEED_RETAIL,
    },
  },
  {
    path: 'lightspeed-retail-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/lightspeed-retail/lightspeed-retail-configure.component').then(
        (m) => m.LightspeedRetailConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LIGHTSPEED_RETAIL,
    },
  },
  {
    path: 'datev-configure',
    loadComponent: () =>
      import('../+modals/integrations/datev-configure/datev-configure.component').then(
        (m) => m.DatevConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.DATEV,
    },
  },
  {
    path: 'datev-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/datev-configure/datev-configure.component').then(
        (m) => m.DatevConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.DATEV,
    },
  },
  {
    path: 'easylon-configure',
    loadComponent: () =>
      import('../+modals/integrations/easylon-configure/easylon-configure.component').then(
        (m) => m.EasylonConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.EASYLON,
    },
  },
  {
    path: 'easylon-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/easylon-configure/easylon-configure.component').then(
        (m) => m.EasylonConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.EASYLON,
    },
  },
  {
    path: 'connexie-configure',
    loadComponent: () =>
      import('../+modals/integrations/connexie-configure/connexie-configure.component').then(
        (m) => m.ConnexieConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.CONNEXIE,
    },
  },
  {
    path: 'connexie-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/connexie-configure/connexie-configure.component').then(
        (m) => m.ConnexieConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.CONNEXIE,
    },
  },
  {
    path: 'employes-configure',
    loadComponent: () =>
      import('../+modals/integrations/employes-configure/employes-configure.component').then(
        (m) => m.EmployesConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.EMPLOYES,
    },
  },
  {
    path: 'employes-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/employes-configure/employes-configure.component').then(
        (m) => m.EmployesConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.EMPLOYES,
    },
  },
  {
    path: 'hr-salaris-gemak-configure',
    loadComponent: () =>
      import('../+modals/integrations/hr-en-salaris-gemak-configure/hr-en-salaris-gemak-configure.component').then(
        (m) => m.HrEnSalarisGemakConfigureComponent,
      ),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'hr-salaris-gemak-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/hr-en-salaris-gemak-configure/hr-en-salaris-gemak-configure.component').then(
        (m) => m.HrEnSalarisGemakConfigureComponent,
      ),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'hiopos-configure',
    loadComponent: () =>
      import('../+modals/integrations/hiopos-configure/hiopos-configure.component').then(
        (m) => m.HioposConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.HIOPOS,
    },
  },
  {
    path: 'hiopos-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/hiopos-configure/hiopos-configure.component').then(
        (m) => m.HioposConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.HIOPOS,
    },
  },
  {
    path: 'afas-configure',
    loadComponent: () =>
      import('../+modals/integrations/afas-configure/afas-configure.component').then((m) => m.AfasConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.AFAS,
    },
  },
  {
    path: 'afas-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/afas-configure/afas-configure.component').then((m) => m.AfasConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.AFAS,
    },
  },
  {
    path: 'formitable-configure',
    loadComponent: () =>
      import('../+modals/integrations/formitable-configure/formitable-configure.component').then(
        (m) => m.FormitableConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.FORMITABLE,
    },
  },
  {
    path: 'formitable-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/formitable-configure/formitable-configure.component').then(
        (m) => m.FormitableConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.FORMITABLE,
    },
  },
  {
    path: 'formitable-request',
    loadComponent: () =>
      import('../+modals/integrations/formitable-configure/formitable-request.component').then(
        (m) => m.FormitableRequestComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.FORMITABLE,
    },
  },
  {
    path: 'loon-configure',
    loadComponent: () =>
      import('../+modals/integrations/loon-configure/loon-configure.component').then((m) => m.LoonConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LOON_NL,
    },
  },
  {
    path: 'loon-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/loon-configure/loon-configure.component').then((m) => m.LoonConfigureComponent),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.LOON_NL,
    },
  },
  {
    path: 'sd-worx-configure',
    loadComponent: () =>
      import('../+modals/integrations/sd-worx-configure/sd-worx-configure.component').then(
        (m) => m.SdWorxConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.SD_WORX,
    },
  },
  {
    path: 'sd-worx-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/sd-worx-configure/sd-worx-configure.component').then(
        (m) => m.SdWorxConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.SD_WORX,
    },
  },
  {
    path: 'guestplan-request',
    loadComponent: () =>
      import('../+modals/integrations/guestplan-configure/guestplan-request.component').then(
        (m) => m.GuestplanRequestComponent,
      ),
    canActivate: [PermissionGuard, IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.GUESTPLAN,
    },
  },
  {
    path: 'guestplan-configure',
    loadComponent: () =>
      import('../+modals/integrations/guestplan-configure/guestplan-configure.component').then(
        (m) => m.GuestplanConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.GUESTPLAN,
    },
  },
  {
    path: 'guestplan-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/guestplan-configure/guestplan-configure.component').then(
        (m) => m.GuestplanConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.GUESTPLAN,
    },
  },

  {
    path: 'app-configure/:app',
    loadComponent: () =>
      import('../+modals/integrations/configure/app-configure.component').then((m) => m.AppConfigureComponent),
    canActivate: [IntegrationAppParamGuard],
    outlet: 'modal',
  },
  {
    path: 'app-configure/:app/:id',
    loadComponent: () =>
      import('../+modals/integrations/configure/app-configure.component').then((m) => m.AppConfigureComponent),
    canActivate: [IntegrationAppParamGuard],
    outlet: 'modal',
  },

  {
    path: 'holiday-import',
    loadComponent: () =>
      import('../+modals/settings/holiday-import/holiday-import.component').then((m) => m.HolidayImportComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'clock-ip-address',
    loadComponent: () =>
      import('../+modals/settings/clock-ip-address/clock-ip-address.component').then((m) => m.ClockIpAddressComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'clock-ip-address/:id',
    loadComponent: () =>
      import('../+modals/settings/clock-ip-address/clock-ip-address.component').then((m) => m.ClockIpAddressComponent),
    canActivate: [PermissionGuard, ClockIpAddressExistsGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
  },
  {
    path: 'kiosk',
    loadComponent: () => import('../+modals/settings/kiosk/kiosk.component').then((m) => m.KioskComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'kiosk/:id',
    loadComponent: () => import('../+modals/settings/kiosk/kiosk.component').then((m) => m.KioskComponent),
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'custom-fields/:appliesTo',
    loadComponent: () =>
      import('../+modals/settings/custom-fields/custom-fields-modal.component').then(
        (m) => m.CustomFieldsModalComponent,
      ),
    canActivate: [SubscriptionPlanGuard, CustomFieldGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'custom-fields/:appliesTo/:id',
    loadComponent: () =>
      import('../+modals/settings/custom-fields/custom-fields-modal.component').then(
        (m) => m.CustomFieldsModalComponent,
      ),
    canActivate: [SubscriptionPlanGuard, CustomFieldGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'absence-restriction',
    loadComponent: () =>
      import('../+modals/settings/absence-restriction/absence-restriction.modal.component').then(
        (m) => m.AbsenceRestrictionModalComponent,
      ),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'absence-restriction/:id',
    loadComponent: () =>
      import('../+modals/settings/absence-restriction/absence-restriction.modal.component').then(
        (m) => m.AbsenceRestrictionModalComponent,
      ),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'overtime-policies',
    loadComponent: () =>
      import('../+modals/settings/overtime-policies/overtime-policy.modal.component').then(
        (m) => m.OvertimePolicyModalComponent,
      ),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'overtime-policies/:id',
    loadComponent: () =>
      import('../+modals/settings/overtime-policies/overtime-policy.modal.component').then(
        (m) => m.OvertimePolicyModalComponent,
      ),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'time-off-balances',
    loadComponent: () =>
      import('../+modals/settings/time-off-balances/time-off-balances-modal.component').then(
        (m) => m.TimeOffBalancesModalComponent,
      ),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.PREMIUM,
    },
  },
  {
    path: 'time-off-balances/:id',
    loadComponent: () =>
      import('../+modals/settings/time-off-balances/time-off-balances-modal.component').then(
        (m) => m.TimeOffBalancesModalComponent,
      ),
    canActivate: [SubscriptionPlanGuard],
    outlet: 'modal',
    data: {
      subscriptionPlan: PlanType.BASIC,
    },
  },
  {
    path: 'report-favorite/save',
    component: ReportFavoriteModalComponent,
    outlet: 'modal',
    data: {
      permission: ['View reports'],
      subscriptionPlan: PlanType.PREMIUM,
    },
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
  },
  {
    path: 'report-favorite/list',
    component: ReportFavoriteListModalComponent,
    outlet: 'modal',
    data: {
      permission: ['View reports'],
      subscriptionPlan: PlanType.PREMIUM,
    },
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
  },

  {
    path: 'report-recurring/save',
    component: ReportRecurringModalComponent,
    outlet: 'modal',
    data: {
      permission: ['View reports'],
      subscriptionPlan: PlanType.PREMIUM,
    },
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
  },

  {
    path: 'report-recurring/list',
    loadComponent: () =>
      import('../+modals/report/recurring/list/report-recurring-list-modal.component').then(
        (m) => m.ReportRecurringListModalComponent,
      ),
    outlet: 'modal',
    data: {
      permission: ['View reports'],
      subscriptionPlan: PlanType.PREMIUM,
    },
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
  },

  {
    path: 'billing/cancellation',
    loadComponent: () =>
      import('../+authenticated/+account/billing/modals/cancellation/subscription-cancellation-modal.component').then(
        (m) => m.SubscriptionCancellationModalComponent,
      ),
    outlet: 'modal',
    data: {
      permission: ['Manage account'],
    },
    canActivate: [PermissionGuard],
  },

  {
    path: 'billing/details',
    loadComponent: () =>
      import('../+authenticated/+account/billing/modals/billing-details/billing-details-modal.component').then(
        (m) => m.BillingDetailsModalComponent,
      ),
    outlet: 'modal',
    data: {
      permission: ['Manage account'],
    },
    canActivate: [PermissionGuard],
  },
  {
    path: 'billing/plan-wizard',
    loadComponent: () =>
      import('../+authenticated/+account/billing/modals/plan-wizard/plan-wizard-modal.component').then(
        (m) => m.PlanWizardModalComponent,
      ),
    outlet: 'modal',
    data: {
      permission: ['Manage account'],
    },
    canActivate: [PermissionGuard],
  },
  {
    path: 'jacando-request',
    loadComponent: () =>
      import('../+modals/integrations/jacando-configure/jacando-request.component').then(
        (m) => m.JacandoRequestComponent,
      ),
    canActivate: [PermissionGuard, IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.JACANDO,
    },
  },
  {
    path: 'jacando-configure',
    loadComponent: () =>
      import('../+modals/integrations/jacando-configure/jacando-configure.component').then(
        (m) => m.JacandoConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.JACANDO,
    },
  },
  {
    path: 'jacando-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/jacando-configure/jacando-configure.component').then(
        (m) => m.JacandoConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.JACANDO,
    },
  },
  {
    path: 'hooray-configure',
    loadComponent: () =>
      import('../+modals/integrations/hooray-configure/hooray-configure.component').then(
        (m) => m.HoorayConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.HOORAY,
    },
  },
  {
    path: 'hooray-configure/:id',
    loadComponent: () =>
      import('../+modals/integrations/hooray-configure/hooray-configure.component').then(
        (m) => m.HoorayConfigureComponent,
      ),
    canActivate: [IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.HOORAY,
    },
  },
  {
    path: 'add-absence-policy',
    loadComponent: () =>
      import('./settings/add-absence-policy/add-absence-policy.component').then((m) => m.AddAbsencePolicyComponent),
    outlet: 'modal',
    data: {
      permission: [PermissionType.MANAGE_ACCOUNT],
      subscriptionPlan: PlanType.EARLY_ADOPTER,
    },
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
  },
  {
    path: 'activate-absence-type/:policyId/:absenceTypeId',
    loadComponent: () =>
      import('./settings/activate-absence-type/activate-absence-type.component').then(
        (m) => m.ActivateAbsenceTypeComponent,
      ),
    outlet: 'modal',
    data: {
      permission: [PermissionType.MANAGE_ACCOUNT],
      subscriptionPlan: PlanType.BASIC,
    },
    canActivate: [PermissionGuard, SubscriptionPlanGuard],
  },
  {
    path: 'delete-team/:teamId',
    loadComponent: () => import('./settings/delete-team/delete-team.component').then((m) => m.DeleteTeamComponent),
    outlet: 'modal',
    data: {
      permission: [PermissionType.MANAGE_ACCOUNT],
    },
    canActivate: [PermissionGuard],
  },
  {
    path: 'deactivate-department/:departmentId',
    loadComponent: () =>
      import('./settings/deactivate-department/deactivate-department.component').then(
        (m) => m.DeactivateDepartmentComponent,
      ),
    outlet: 'modal',
    data: {
      permission: [PermissionType.MANAGE_ACCOUNT],
    },
    canActivate: [PermissionGuard, DepartmentDeactivationGuard],
  },
  {
    path: 'bork-request',
    loadComponent: () =>
      import('../+modals/integrations/bork-configure/bork-request.component').then((m) => m.BorkRequestComponent),
    canActivate: [PermissionGuard, IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.BORK,
    },
  },
  {
    path: 'jixbee-request',
    loadComponent: () =>
      import('../+modals/integrations/jixbee-configure/jixbee-request.component').then((m) => m.JixbeeRequestComponent),
    canActivate: [PermissionGuard, IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.JIXBEE,
    },
  },
  {
    path: 'nextup-request',
    loadComponent: () =>
      import('../+modals/integrations/nextup-configure/nextup-request.component').then((m) => m.NextUpRequestComponent),
    canActivate: [PermissionGuard, IntegrationAppGuard],
    outlet: 'modal',
    data: {
      appTitle: IntegrationAppTitle.NEXTUP,
    },
  },
];

@NgModule({
  imports: [
    SharedAuthModule,
    RouterModule.forChild(modalRoutes),
    SharedTimesheetModule,
    SharedModule,
    SharedEmployeeModule,
    SbFormModules,
    SbDropdownModule,
    KnowledgeBaseArticleLinkModule,
    SbTableSelectModule,
    RippleModule,
    AppConfigureModule,
    DropdownModule,
    CheckboxComponent,
    DecimalPipe,
    ScheduleShiftTemplateComponent,
    NoticeComponent,
    CustomFieldPipe,
    IconComponent,
    TooltipModule,
    BadgeComponent,
    RecurringScheduleHtmlPipe,
    StepsModule,
    MultiselectComponent,
    TimesheetClockInfoComponent,
    SelectComponent,
    LocaleDatePipe,
    OldNoticeComponent,
  ],
  providers: [CustomFieldGuard, IntroductionModalService],
  declarations: [ReportRecurringModalComponent, ReportFavoriteModalComponent, ReportFavoriteListModalComponent],
})
export class ModalModule {}
