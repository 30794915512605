import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AbsenceRequestDirective } from '../../../+modals/employee/absence-request/absence-request.directive';
import { EnhancedAbsenceModel } from '../../../reducers/orm/absence/absence.model';
import { AbsenceSummaryItemComponent } from '../../../shared/absence/absence-summary-item/absence-summary-item.component';
import { SidebarHeaderComponent } from './sidebar-header.component';
import { SidebarRequestItemDirective } from './sidebar-request-item.directive';

@Component({
  selector: 'absence-request-notifications',
  templateUrl: './absence-request-notifications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AbsenceSummaryItemComponent, SidebarHeaderComponent, SidebarRequestItemDirective, AbsenceRequestDirective],
})
export class AbsenceRequestNotificationsComponent implements OnChanges {
  @Input()
  public absences: EnhancedAbsenceModel[];

  @Input()
  public isOwnAbsences: boolean;

  @Input()
  public title = this.translate.instant('Pending absences');

  public constructor(private translate: TranslateService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['title']) {
      this.title = this.translate.instant(this.title);
    }
  }
}
