<div class="page" [ngClass]="{ 'p-0': removeDashboardPadding }">
  <sb-sidebar [menuItems]="menuItems" [title]="'My' | translate" (onLocked)="handleOnLock($event)"> </sb-sidebar>
  <div class="w-full min-w-1">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</div>
<ng-template #announcementContent>
  <p>
    {{
      'We are introducing a new feature that gives you more flexibility in managing public holidays within your organization.'
        | translate
    }}
  </p>
  <ul>
    <li>{{ 'New: it is now possible to set up "public holiday groups"' | translate }}</li>
    <li>{{ 'Groups can include both imported and manually added holidays' | translate }}</li>
    <li>{{ 'Link groups to departments for accurate surcharge calculations' | translate }}</li>
    <li>
      {{
        'Automatic time off on public holidays: a new setting in Absence, allowing direct assignment of days off on specific holidays'
          | translate
      }}
    </li>
    <li>{{ 'Ideal for unique holidays in cross-border regions or provinces' | translate }}</li>
  </ul>
</ng-template>
