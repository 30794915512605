@if (absences.length > 0) {
  <sidebar-header [title]="title" [indicator]="isOwnAbsences ? 0 : absences.length" />
  @for (absence of absences; track absence.id) {
    <div sidebarRequestItem>
      <absence-summary-item
        [appAbsenceRequest]="{
          absenceId: absence.id,
          isOwnAbsence: isOwnAbsences,
          originator: 'request-sidebar'
        }"
        [absence]="absence"
        [isOwnAbsence]="isOwnAbsences"
        [showStatus]="isOwnAbsences"
        [inline]="true"
        [compact]="true"
        [rangeClickShouldNavigate]="true"
      />
    </div>
  }
}
