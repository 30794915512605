import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppEffects, AppReducers, metaReducers } from '@reducers/index';
import { MapSettingsLoader, MapsModule } from '@sb/maps';
import { IconRegistry, SbDialogModule } from '@sb/ui';
import * as Sentry from '@sentry/angular-ivy';
import { Angulartics2Module } from 'angulartics2';
import { PhraseAppCompiler } from 'ngx-translate-phraseapp';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { RippleModule } from 'primeng/ripple';

import { environment } from '../environments/environment';
import { PlanWizardComponent } from './+authenticated/+account/billing/plan-wizard/plan-wizard.component';
import { AuthenticatedModule } from './+authenticated/authenticated.module';
import { NotificationsComponent } from './+authenticated/shared/notifications/notifications.component';
import { AccountClosedGuard } from './+errors/account-closed/account-closed-guard.service';
import { AccountClosedComponent } from './+errors/account-closed/account-closed.component';
import { DowngradeGuard } from './+errors/downgrade-steps/downgrade-guard.service';
import { DowngradeComponent } from './+errors/downgrade-steps/downgrade-steps.component';
import { LockedGuard } from './+errors/locked/locked-guard.service';
import { LockedComponent } from './+errors/locked/locked.component';
import { PageNotFoundComponent } from './+errors/page-not-found/page-not-found.component';
import { TrialExpiredGuard } from './+errors/trial-expired/trial-expired-guard.service';
import { TrialExpiredComponent } from './+errors/trial-expired/trial-expired.component';
import { ModalModule } from './+modals/modal.module';
import { ErrorInterceptor } from './api/interceptors/error.interceptor';
import { MetaInterceptor } from './api/interceptors/meta.interceptor';
import { RefreshAuthInterceptor } from './api/interceptors/refresh-auth.interceptor';
import { TrackingInterceptor } from './api/interceptors/tracking.interceptor';
import { XsrfInterceptor } from './api/interceptors/xsrf.interceptor';
import { XurfInterceptor } from './api/interceptors/xurf.interceptor';
import { AppComponent } from './app.component';
import { AppErrorHandler } from './error-handler';
import { provideFeatureService } from './providers/feature-service.initializer';
import { provideMask } from './providers/ngx-mask.provider';
import { ormReducers } from './reducers/orm/index';
import { RefinerService } from './services/refiner.services';
import { DomEventsNoChangeDetectionPlugin } from './shared/dom-events-no-change-detection.plugin';
import { FallBackTranslationHandler } from './shared/fallback-translation.handler';
import { TreeviewModule } from './shared/ngx-treeview/treeview.module';
import { SbPlanLockDialogComponent } from './shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.component';
import { SharedModule } from './shared/shared.module';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./+anonymous/anonymous.module').then((m) => m.AnonymousModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./+signup/signup.module').then((m) => m.SignupModule),
  },
  {
    path: 'locked',
    component: LockedComponent,
    canActivate: [LockedGuard],
  },
  {
    path: 'account-closed',
    component: AccountClosedComponent,
    canActivate: [AccountClosedGuard],
  },
  {
    path: 'trial-expired',
    component: TrialExpiredComponent,
    canActivate: [TrialExpiredGuard],
  },
  {
    path: 'downgrade',
    component: DowngradeComponent,
    canActivate: [DowngradeGuard],
  },
  { path: '**', component: PageNotFoundComponent },
];

// AoT requires an exported function for factories
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './locale/', '.json?v=' + environment.version);
}

@NgModule({
  // module dependencies
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule.disable(),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: PhraseAppCompiler,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: FallBackTranslationHandler,
      },
    }),
    SharedModule.forRoot(),
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
    }),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearQueryParams: true,
      },
    }),
    TreeviewModule.forRoot(),
    MapsModule.forRoot({
      provide: MapSettingsLoader,
      useFactory: () => ({ mapsKey: environment.mapsKey }),
    }),
    StoreModule.forRoot(AppReducers, { metaReducers: metaReducers }),
    StoreModule.forFeature('orm', ormReducers),
    EffectsModule.forRoot(AppEffects),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: false, connectInZone: true }) : [],
    ModalModule,
    AuthenticatedModule,
    DynamicDialogModule,
    RippleModule,
    SbPlanLockDialogComponent,
    PlanWizardComponent,
    SbDialogModule,
  ],
  // components and directives
  declarations: [
    AppComponent,
    TrialExpiredComponent,
    AccountClosedComponent,
    LockedComponent,
    PageNotFoundComponent,
    NotificationsComponent,
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: XurfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MetaInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TrackingInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (iconRegistry: IconRegistry) => async () => {
        const { completeIconSet } = await import('@sb/svg-icons');
        iconRegistry.registerIcons(completeIconSet);
      },
      deps: [IconRegistry],
      multi: true,
    },
    provideFeatureService(),
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: DomEventsNoChangeDetectionPlugin,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    AccountClosedGuard,
    LockedGuard,
    TrialExpiredGuard,
    DowngradeGuard,
    RefinerService,
    provideMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
