import { environment } from './../../../environments/environment';
import { tap } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers/index';
import { MessageAction } from '../../reducers/message/message.action';

interface JsonResponse {
  data?: Object;
  meta?: MetaResponse;
}

interface MetaResponse {
  status: 'ok' | 'error';
  status_message: string;
  feedback: Object;
  refresh_at: string;
}

@Injectable()
export class MetaInterceptor implements HttpInterceptor {
  public constructor(private store: Store<AppState>) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.body && event.body.meta) {
          const { meta } = event.body;
          const ignoreMetaMessage = request?.headers.get('ignore-meta-message') === 'true';

          if (!meta) {
            return;
          }

          if (meta.request && meta.request.remote_addr) {
            environment.ipAddress = meta.request.remote_addr;
          }

          this.checkMetaSuccess(meta, ignoreMetaMessage);
        }
      })
    );
  }

  private checkMetaSuccess(meta: MetaResponse, ignoreMetaMessage: boolean) {
    if (meta.status === 'ok' && meta.status_message && !ignoreMetaMessage) {
      this.store.dispatch(MessageAction.success(meta.status_message));
    }
  }
}
