import { AppState } from '../../index';
import { reportFavoriteEntityAdapter, ReportFavoriteState } from './report-favorite.state';
import { createSelector } from 'reselect';
import { ReportFavoriteModel } from './report-favorite.model';
import omit from 'lodash-es/omit';
import { ReportType } from '../reports/report.model';

export const getReportFavoriteState = (appState: AppState): ReportFavoriteState => appState.orm.reportFavorites;

export const { selectAll, selectEntities, selectIds, selectTotal } = reportFavoriteEntityAdapter.getSelectors(
  getReportFavoriteState
);

export const enhanceReportFavorites = createSelector([selectAll], (reportFavorites: ReportFavoriteModel[]) => reportFavorites.map((reportFavorite: ReportFavoriteModel) => {
    const parameters = reportFavorite.parameters;
    const updatedParams = omit(parameters, ['from', 'to', 'date']);

    return { ...reportFavorite, parameters: updatedParams };
  }));

export const getReportFavorites = (includeOnly: ReportType[]) =>
  createSelector([enhanceReportFavorites], (reportFavorites: ReportFavoriteModel[]) => {
    if (!includeOnly?.length) {
      return reportFavorites;
    }

    return reportFavorites.filter((favorite) => includeOnly.includes(favorite.type));
  });
