<div class="authenticated">
  <sb-header></sb-header>

  <div class="authenticated__wrapper">
    <div class="authenticated__content">
      <router-outlet></router-outlet>
    </div>
  </div>

  @if (checklistItems$ | async; as checklist) {
    <span>
      <sb-checklist
        [ngClass]="!isChatActive ? 'right-8' : 'right-24'"
        *ngIf="onboardingChecklist.showChecklist"
        [title]="'Your path to success begins here!' | translate"
        [subtitle]="'To optimize your account, complete the following tasks;' | translate"
        [progress]="{
          title: '[percentage]% complete' | translate | translationParams: { percentage: checklist.progress },
          subtitle: '[remaining] steps left' | translate | translationParams: { remaining: checklist.remainingSteps },
          level: checklist.progress
        }"
        [tasks]="checklist.tasks"
        (minimized)="showChecklistButton(onboardingChecklist.showChecklist)"
        (closed)="dismissChecklist()"
        (itemClicked)="gotoSteps($event)"
      >
      </sb-checklist>
      <sb-bubble-notification
        [ngClass]="!isChatActive ? 'right-8' : 'right-24'"
        [title]="'Get started' | translate"
        [notificationsCount]="checklist.remainingSteps"
        (clicked)="showChecklistButton(onboardingChecklist.showChecklist)"
      ></sb-bubble-notification>
    </span>
  } @else if (!(authUser$ | async)?.verified) {
    <verification-banner></verification-banner>
  }

  <router-outlet name="aside"></router-outlet>
</div>
