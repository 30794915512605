<rModal>
  <div class="modal-header">
    <div class="modal-title">{{ 'Filter favorites' | translate }}</div>
    <a class="btn btn-clear modal-close" (click)="close()">
      <icon class="si modal-close__icon" svgIcon="close"></icon>
    </a>
  </div>

  <div class="modal-body">
    <div class="card__no-padding">
      <div class="card-table">
        <table class="table-border subscription-list table-uniform-rows">
          <thead class="report-favorite-table-header">
            <tr>
              <th class="name-col">{{ 'Name' | translate }}</th>
              <th>
                <sb-dropdown-button
                  [positions]="positions"
                  [textOnly]="true"
                  [buttonText]="'Type' | translate"
                  [contentTemplate]="filter"
                >
                  <ng-template #filter>
                    <ng-container *ngIf="reportTypes$ | async as reportTypes">
                      <div style="padding: 5px">
                        <dl-multiselect [formControl]="reportTypeCtrl" [items]="reportTypes"></dl-multiselect>
                      </div>
                    </ng-container> </ng-template
                ></sb-dropdown-button>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let favorite of reportFavorites$ | async">
              <td>{{ favorite.name }}</td>
              <td>{{ reportOptionSettings[favorite.type].label | translate }}</td>
              <td class="table-border-padding__actions pr-0">
                <button (click)="loadFavorite(favorite)" class="btn btn-info btn-sm mr-2" type="button">
                  <icon class="si" svgIcon="eye"></icon>&nbsp;
                  {{ 'Load' | translate }}
                </button>
                <action-confirm
                  [attr.aria-label]="'Deactivate' | translate"
                  class="btn btn-clear btn-icon"
                  (onConfirm)="delete(favorite.id)"
                  [icon]="'delete'"
                >
                </action-confirm>
              </td>
            </tr>
          </tbody>
        </table>
        <content-state
          [isEmpty]="(reportFavorites$ | async)?.length === 0"
          [isLoading]="isLoading"
          icon="load"
          size="small"
          [title]="'No favorites found' | translate"
          direction="column"
        >
        </content-state>
      </div>
    </div>
  </div>
</rModal>
