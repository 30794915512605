/* eslint-disable max-lines */
import { Component, DestroyRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { introductionModalConfig } from '@app/+modals/introduction';
import { IntroductionModalComponent } from '@app/+modals/introduction/introduction-modal.component';
import { Features } from '@app/enums';
import { KnowledgeBaseArticleLinkPipe } from '@app/pipes/knowledge-base-article-link.pipe';
import { AppState } from '@app/reducers';
import { getAccountSubscription } from '@app/reducers/account/account.service';
import { PermissionState } from '@app/reducers/auth/auth.model';
import { getPermissionState, hasAllPermissions, hasPermission } from '@app/reducers/auth/permission.helper';
import { EmployeeModel } from '@app/reducers/orm/employee/employee.model';
import { getAuthenticatedUser } from '@app/reducers/orm/employee/employee.service';
import { userGuideUpdateRequest } from '@app/reducers/orm/user-guide';
import { UserGuideStatus, UserGuideType } from '@app/reducers/orm/user-guide/user-guide.model';
import { isUserGuideFinished, isUserGuideLoaded } from '@app/reducers/orm/user-guide/user-guide.selector';
import { UserGuideService } from '@app/reducers/orm/user-guide/user-guide.service';
import { OnboardingChecklistService } from '@app/shared/onboarding-checklist.service';
import { SbPlanLockDialogComponent } from '@app/shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.component';
import { LockType, planLockDialogConfig } from '@app/shared/sb-lib/dynamic-dialogs/plan-lock/sb-plan-lock-dialog.model';
import { SidebarMenuItem } from '@app/shared/sb-lib/sidebar/sidebar.model';
import { hasAtleastSubscriptionPlan } from '@app/shared/subscription-plan/subscription-plan.directive';
import { FeatureService } from '@app/startup/feature.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DialogAnnouncementData, SbDialogService } from '@sb/ui';
import { clsx } from 'clsx';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { PlanType, SubscriptionModel } from '../+reports/shared/subscriptions/subscription.model';
import { DashboardHelperService } from '../shared/sidebar/dashboard-sidebar/dashboard.helper';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { BannerType } from './dashboard-feature-banner-messages';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  providers: [DialogService],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(SidebarComponent, { static: true })
  public sidebar: SidebarComponent;

  @ViewChild('announcementContent', { static: true })
  public announcementContent: TemplateRef<any>;

  public showDashboardWidgetButton: boolean;

  private dataSubs = new Subscription();

  public employeeGreetMessage: string;

  public removeDashboardPadding: boolean;
  public showFeatureBanner: boolean;
  public dashboardFeatureMessage;
  private bannerType?: BannerType;

  public features = Features;

  public planType = PlanType;
  public subscriptionPlan: SubscriptionModel;
  public menuItems: SidebarMenuItem[];
  private permissionState: PermissionState;

  public authenticatedUser$ = this.store.select(getAuthenticatedUser);
  private readonly subscriptionModel$ = this.store.pipe(select(getAccountSubscription));

  public constructor(
    private router: Router,
    private store: Store<AppState>,
    private dialogService: DialogService,
    protected featureService: FeatureService,
    protected userGuideService: UserGuideService,
    private onboardingChecklist: OnboardingChecklistService,
    private readonly dashboardHelperService: DashboardHelperService,
    private readonly sbDialogService: SbDialogService,
    private readonly translate: TranslateService,
    private readonly destroyRef: DestroyRef,
    private readonly knowledgeBaseArticleLinkPipe: KnowledgeBaseArticleLinkPipe,
  ) {}

  public ngOnInit(): void {
    this.dataSubs.add(
      combineLatest([this.store.select(getPermissionState), this.subscriptionModel$]).subscribe(
        ([permissionState, subscriptionModel]) => {
          this.permissionState = permissionState;
          this.menuItems = this.generateMenu(
            this.dashboardHelperService.getDashboardSidebarMenuItem(subscriptionModel),
          );
        },
      ),
    );

    this.dataSubs.add(
      this.router.events.pipe(distinctUntilChanged()).subscribe({
        next: () => {
          this.updateWidgetButtonVisibility();
        },
      }),
    );

    this.setupSignupGreetingModalAndAnnouncement();

    this.updateWidgetButtonVisibility();
    this.updateDashboardPadding();
  }

  private generateMenu(menus: SidebarMenuItem[]): SidebarMenuItem[] {
    return menus.filter((menu) => {
      if (menu?.items?.length > 0) {
        menu.items = this.generateMenu(menu.items);
      }
      const hasPermissionToViewMenu = menu.state?.permissions
        ? hasPermission({ userId: 'me', departments: 'any', permissions: menu.state.permissions }, this.permissionState)
        : true;

      const featureFlag = menu.state?.featureFlag
        ? this.featureService.isFeatureActivated(menu.state.featureFlag)
        : true;

      return hasPermissionToViewMenu && featureFlag;
    });
  }

  private updateWidgetButtonVisibility() {
    this.showDashboardWidgetButton = this.router.url.includes('/dashboard/my-overview');
  }

  private updateDashboardPadding() {
    this.removeDashboardPadding = this.router.url.includes('/dashboard');
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
  }

  private setupSignupGreetingModalAndAnnouncement() {
    this.dataSubs.add(
      this.userGuideService.isReady
        .pipe(
          filter((ready) => ready),
          switchMap(() =>
            combineLatest([
              this.onboardingChecklist.isChecklistInProgress$.pipe(),
              this.store.pipe(select(isUserGuideFinished(UserGuideType.SIGNUP_GREETING_MODAL))),
            ]),
          ),
          debounceTime(300),
          take(1),
          withLatestFrom(this.authenticatedUser$),
        )
        .subscribe({
          next: ([[checklistGuideInProgress, signupGreetingFinished], authenticatedUser]) => {
            if (checklistGuideInProgress && !signupGreetingFinished) {
              this.showOnboardingChecklistModal(authenticatedUser);
              return;
            }
            // Prevent announcement if the announcements are disabled
            // This flow is a work around for e2e testing until we have another soloution or move announcements to another platform
            if (sessionStorage.getItem('disableAnnouncements') === 'true') {
              return;
            }
            this.setupAnnouncementModal();
          },
        }),
    );
  }

  public showIntroductionModal(data): void {
    this.dialogService.open(IntroductionModalComponent, {
      data,
      ...introductionModalConfig,
    });
  }

  private setupAnnouncementModal() {
    void this.featureService.featureClientReady
      .pipe(
        filter((ready) => ready),
        map(
          () =>
            this.featureService.isFeatureActivated(Features.TMP_AUTOMATED_ABSENCE_CREATION_PUBLIC_HOLIDAYS) &&
            this.featureService.isFeatureActivated(
              Features.TMP_AUTOMATED_ABSENCE_CREATION_PUBLIC_HOLIDAYS_ANNOUNCEMENT,
            ),
        ),
        filter((featureOn) => featureOn),
        switchMap(() => this.subscriptionModel$),
        filter((subscription) => hasAtleastSubscriptionPlan(PlanType.BASIC, subscription)),
        switchMap(() => this.store.select(getPermissionState)),
        withLatestFrom(this.authenticatedUser$),
        filter(([permissionState, authenticatedUser]) =>
          hasAllPermissions(
            {
              userId: authenticatedUser?.id,
              permissions: ['Manage account'],
            },
            permissionState,
          ),
        ),
        switchMap(() => this.store.select(isUserGuideLoaded)),
        filter((loaded) => loaded),
        switchMap(() =>
          this.store.select(isUserGuideFinished(UserGuideType.TAUTOMATED_ABSENCE_CREATION_PUBLIC_HOLIDAYS)),
        ),
        filter((finished) => !finished),
        take(1),
        switchMap(() => this.sbDialogService.openAnnouncement(this.announcementModalCopy()).closed),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.store.dispatch(
          userGuideUpdateRequest({
            userGuide: {
              guide: UserGuideType.TAUTOMATED_ABSENCE_CREATION_PUBLIC_HOLIDAYS,
              status: UserGuideStatus.FINISHED,
            },
          }),
        );
      });
  }

  private announcementModalCopy(): DialogAnnouncementData {
    return {
      title: this.translate.instant('Good news!'),
      contentTemplate: this.announcementContent,
      badge: this.translate.instant('New'),
      image: {
        src: 'assets/images/announcement/automated-absence-creation-public-holidays.svg',
        classNames: clsx('border-2 border-solid border-primary-100/25 bg-primary-50'),
      },
      primary: {
        text: this.translate.instant('Go to settings'),
        onClick: () => {
          this.router.navigate(['account/settings/modules/employees/holiday-groups']);
        },
      },
      secondary: {
        href: this.knowledgeBaseArticleLinkPipe.transform('how-can-i-add-public-holidays'),
      },
    };
  }

  public showOnboardingChecklistModal(user: EmployeeModel): void {
    this.onboardingChecklist.showChecklist = false;

    void this.sbDialogService
      .openAnnouncement({
        title: this.translate.instant('Welcome {{name}}!', { name: user.first_name }),
        description: this.translate.instant(
          'Shiftbase is your all-in-one solution for effortless workforce management. Simplify scheduling, time tracking, and communication to boost productivity.<br><br><br> To get started, click the button below to set up your organization.',
        ),
        image: {
          src: 'assets/images/announcement/welcome.svg',
        },
        primary: {
          text: this.translate.instant('Get started'),
          onClick: () => {
            this.onboardingChecklist.showChecklist = true;
            this.router.navigate(['account', 'settings', 'locations']);
          },
        },
      })
      .closed.pipe(
        take(1),
        switchMap(() =>
          this.userGuideService.trackUserGuide({
            guide: UserGuideType.SIGNUP_GREETING_MODAL,
            status: UserGuideStatus.FINISHED,
          }),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  public handleOnLock(lockType: LockType) {
    this.dialogService.open(SbPlanLockDialogComponent, {
      data: {
        lockType: lockType,
      },
      ...planLockDialogConfig,
    });
  }
}
