import { ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Features } from '@app/enums';
import { AuthApi } from '@app/reducers/auth/auth.api';
import { getAuthenticatedUser } from '@app/reducers/orm/employee/employee.service';
import { UserGuideService } from '@app/reducers/orm/user-guide/user-guide.service';
import { RefinerService } from '@app/services/refiner.services';
import { ChecklistModel, ChecklistStep, OnboardingChecklistService } from '@app/shared/onboarding-checklist.service';
import { FeatureService } from '@app/startup/feature.service';
import { HubSpotService } from '@app/startup/hub-spot.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { filter, interval, Observable, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
})
export class AuthenticatedComponent implements OnInit {
  public dataSubs = new Subscription();
  public checklistItems$: Observable<ChecklistModel>;
  public features = Features;
  public isChatEnabled$: Observable<boolean>;
  public readonly authUser$ = this.store.select(getAuthenticatedUser);
  public isChatActive: boolean;

  public constructor(
    private router: Router,
    public store: Store,
    protected userGuideService: UserGuideService,
    public onboardingChecklist: OnboardingChecklistService,
    public cookieService: CookieService,
    public translate: TranslateService,
    public cd: ChangeDetectorRef,
    private feature: FeatureService,
    private readonly hubspotService: HubSpotService,
    private refinerService: RefinerService,
    private readonly destroyRef: DestroyRef,
    private readonly authApi: AuthApi,
  ) {
    this.checklistItems$ = this.onboardingChecklist.items$;

    this.dataSubs.add(
      this.hubspotService.isChatEnabled$.subscribe((response) => {
        this.isChatActive = response;
      }),
    );

    this.isChatActive = this.cookieService.get('hubspotChatVisible') === 'true';
  }

  public ngOnInit(): void {
    this.watchUptime();
    this.watchUserVerification();

    if (this.feature.isFeatureActivated(Features.TMP_REFINER)) {
      this.refinerService.init();
    }
  }

  private watchUserVerification(): void {
    this.dataSubs.add(
      this.onboardingChecklist.steps$
        .pipe(
          filter((steps) => !!steps && !steps.includes(ChecklistStep.VERIFY_EMAIL)),
          switchMap(() => this.authUser$),
          filter((authUser) => authUser?.verified),
        )
        .subscribe(() => {
          this.onboardingChecklist.updateSteps(ChecklistStep.VERIFY_EMAIL);
        }),
    );
  }

  public dismissChecklist() {
    this.dataSubs.add(
      this.onboardingChecklist.dismiss().subscribe({
        next: () => {
          this.cd.detectChanges();
        },
      }),
    );
  }

  public showChecklistButton(show: boolean) {
    this.onboardingChecklist.showChecklist = !show;
  }

  public gotoSteps(link: string[]) {
    this.router.navigate(link);
  }

  private watchUptime() {
    void interval(60000)
      .pipe(
        filter(() => this.feature.isFeatureActivated('tmp_uptime_polling')),
        switchMap(() => this.authApi.uptime()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
