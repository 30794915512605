<div
  class="absolute top-full z-[2000] flex h-screen w-72 max-w-full cursor-default flex-col gap-2 overflow-hidden border border-grey-200 bg-white py-2 transition-all duration-300 ease-in-out xl:right-[15px] xl:top-[45px] xl:flex xl:h-fit xl:w-48 xl:rounded-[5px] xl:shadow-lg"
  [ngClass]="{
    'xl:hidden': !open,
    'right-0': open,
    '-right-72': !open
  }"
>
  <!-- Clock buttons -->
  <ng-container *permission="['Clock time', 'Clock own time']; department: 'any'; user: 'me'">
    <sb-profile-clock-in-item
      *ngIf="!(headerService.clockState$ | async)?.Timesheet?.active_clock"
    ></sb-profile-clock-in-item>
    <ng-container *ngIf="(headerService.clockState$ | async)?.Timesheet?.active_clock">
      <sb-profile-clock-out-item></sb-profile-clock-out-item>
      <sb-profile-clock-switch-item *ngIf="headerService.showSwitchButton$ | async"></sb-profile-clock-switch-item>
    </ng-container>
  </ng-container>

  <ng-container *subscriptionPlan="planType.BASIC">
    <!-- Requests -->
    <sb-profile-item
      (click)="headerService.supervisorRequestsSidebarOpen$.next(true)"
      *permission="['Approve absentee', 'Approve exchange']; user: 'me'; department: 'any'"
      [title]="'Requests' | translate"
      [notificationCount]="supervisorOpenRequests$ | async"
    ></sb-profile-item>

    <!-- My Requests -->
    <sb-profile-item
      (click)="headerService.myRequestsSidebarOpen$.next(true)"
      [title]="'My Requests' | translate"
      [notificationCount]="myOpenRequests$ | async"
    ></sb-profile-item>

    <!-- Divider -->
    <hr class="m-0 w-full bg-grey-200" />
  </ng-container>

  <!-- My profile -->
  <sb-profile-item
    *permission="['View own profile', 'Edit own profile']; user: 'me'; department: 'any'"
    routerLink="/my-account/profile"
    [title]="'My profile' | translate"
  ></sb-profile-item>

  <!-- My login -->
  <sb-profile-item routerLink="/my-account/login" [title]="'My login' | translate"></sb-profile-item>

  <!-- My notifications -->
  <sb-profile-item routerLink="/my-account/notifications" [title]="'My notifications' | translate"></sb-profile-item>

  <!-- My files -->
  <sb-profile-item
    *subscriptionPlan="planType.BASIC"
    routerLink="/my-account/files"
    [title]="'My files' | translate"
  ></sb-profile-item>

  <!-- Divider -->
  <hr class="m-0 w-full bg-grey-200" />

  <!-- System updates >>> TODO: Feature flag? -->
  <sb-profile-item
    *subscriptionPlan="planType.BASIC"
    href="https://help.shiftbase.com/shiftbase-release-notes"
    [title]="'System updates' | translate"
  ></sb-profile-item>

  <!-- Logout -->
  <sb-profile-item routerLink="/logout" [title]="'Log out' | translate"></sb-profile-item>
</div>
<!-- Backdrop -->
<div
  class="duration-800 absolute right-0 top-full z-[1999] mt-px h-screen w-screen bg-black transition-all ease-in-out xl:hidden"
  [ngClass]="{ invisible: !open, 'opacity-80': open, 'opacity-0': !open }"
  (click)="headerService.profileDropdownOpen$.next(false)"
></div>
